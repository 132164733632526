import { Product } from './CustomTypes';
import AmaroE from '../media/coffee/amaro-e.png';
import BlackColumbus from '../media/coffee/black-columbus.png';
import BlackMassai from '../media/coffee/black-massai.png';
import BlackNo1 from '../media/coffee/black-no-1.png';
import GiulioCrema from '../media/coffee/giulio-crema.png';
import Confianza from '../media/coffee/confianza.png';
import ElBar from '../media/coffee/el-bar.png';
import MonteFino from '../media/coffee/monte-fino.png';
import Scala from '../media/coffee/scala.png';
import Valioso from '../media/coffee/valioso.png';
import Viena from '../media/coffee/viena.png';
import Peru from '../media/coffee/peru.png';
import OrangUtan from '../media/coffee/orang-utan.png';
import ECaliente from '../media/coffee/e-caliente.png';
import EConfianza from '../media/coffee/e-confianza.png';
import EFuego from '../media/coffee/e-fuego.png';
import EGuiulio from '../media/coffee/e-giulio.png';
import EJavaBlu from '../media/coffee/e-java-blu.png';
import EVero from '../media/coffee/e-vero.png';
import EVulcano from '../media/coffee/e-vulcano.png';
import MonteDecaff from '../media/coffee/monte-decaff-2.png';
import Elegante from '../media/coffee/elegante.png';
import CremaAllrounder from '../media/coffee/crema-allrounder.png';
import Grandioso from '../media/coffee/grandioso.png';
import EExpert from '../media/coffee/e-expert.png';
import SBio from '../media/coffee/s-bio.png';
import SClassic from '../media/coffee/s-hot-classic.png';
import SKaofix from '../media/coffee/s-kaofix.png';
import STrink from '../media/coffee/s-trink.png';
import SDunkleTrink from '../media/coffee/s-dunkle.png';
import EGrandioso from '../media/coffee/e-grandioso.png';
import SMikao from '../media/coffee/s-mikao.png';
import AmaroEDolceCard from '../media/productCards/sk-amaro-e-dolce.jpg';
import BlackColumbusCard from '../media/productCards/sk-black-columbus.jpg';
import BlackMassaiCard from '../media/productCards/sk-black-massai.jpg';
import BlackNo1Card from '../media/productCards/sk-black-no1.jpg';
import ConfianzaCard from '../media/productCards/sk-confianza.jpg';
import CremaAllrounderCard from '../media/productCards/sk-crema-allrounder.jpg';
import CremaGiulioCard from '../media/productCards/sk-crema-giulio.jpg';
import MonteDecaffCard from '../media/productCards/sk-decaff.jpg';
import ECalienteCard from '../media/productCards/sk-e-caliente.jpg';
import EConfianzaCard from '../media/productCards/sk-e-confianza.jpg';
import EExpertCard from '../media/productCards/sk-e-expert.jpg';
import EFuegoCard from '../media/productCards/sk-e-fuego.jpg';
import EGiulioCard from '../media/productCards/sk-e-giulio.jpg';
import EGrandiosoCard from '../media/productCards/sk-e-grandioso.jpg';
import EJavaBluCard from '../media/productCards/sk-e-java-blu.jpg';
import EVeroCard from '../media/productCards/sk-e-vero.jpg';
import EVulcanoCard from '../media/productCards/sk-e-vulcano.jpg';
import ElBarCard from '../media/productCards/sk-el-bar.jpg';
import EleganteCard from '../media/productCards/sk-elegante.jpg';
import GrandiosoCard from '../media/productCards/sk-grandioso.jpg';
import MonteFinoCard from '../media/productCards/sk-monte-fino.jpg';
import OrangUtanCard from '../media/productCards/sk-orang-utan.jpg';
import PeruCard from '../media/productCards/sk-peru.jpg';
import ScalaCard from '../media/productCards/sk-scala.jpg';
import ValiosoCard from '../media/productCards/sk-valioso.jpg';
import VienaCard from '../media/productCards/sk-viena.jpg';

export const productAmaroEDolce: Product = {
    name: 'AMARO E DOLCE',
    description: {
        ingredients: '50% Arabica, 50% Washed Robusta ',
        tasteTags: 'würzig - malzig',
        taste: 'Nussig-herb, mit Noten von Kakao und Karamell.',
    },
    image: AmaroE,
    productCard: AmaroEDolceCard,
};

export const productBlackColumbus: Product = {
    name: 'BLACK COLUMBUS',
    description: {
        ingredients: '100% Arabica',
        tasteTags: 'Karamellnote - schokoladig',
        taste: 'Leicht würzig, mit angenehmer Süße.',
    },
    image: BlackColumbus,
    productCard: BlackColumbusCard,
};

export const productBlackMassai: Product = {
    name: 'BLACK MASSAI',
    description: {
        ingredients: '100% Arabica',
        tasteTags: 'beerig - schokoladig',
        taste: 'Mit Noten von Haselnuss und Rhabarber.',
    },
    image: BlackMassai,
    productCard: BlackMassaiCard,
};

export const productBlackNo1: Product = {
    name: 'BLACK NO 1',
    description: {
        ingredients: '100% Arabica',
        tasteTags: 'Zitrusnote - beerig',
        taste: 'Würzig, mit Noten schwarzer Johannisbeere.',
    },
    image: BlackNo1,
    productCard: BlackNo1Card,
};

export const productCremaAllrounder: Product = {
    name: 'CREMA ALLROUNDER',
    description: {
        ingredients: '60% Arabica, 40% Washed Robusta',
        tasteTags: 'süß - würzig',
        taste: 'Süß-würzig, mit Noten dunkler Schokolade und Nuss.',
    },
    image: CremaAllrounder,
    productCard: CremaAllrounderCard,
};

export const productCremaGiulio: Product = {
    name: 'CREMA GIULIO',
    description: {
        ingredients: '90% Arabica, 10% Washed Robusta',
        tasteTags: 'kräftig-würzig - Kakaonote',
        taste: 'Nussig, mit Noten von Karamell und Kakao.',
    },
    image: GiulioCrema,
    productCard: CremaGiulioCard,
};

export const productConfianza: Product = {
    name: 'CONFIANZA',
    description: {
        ingredients: '70% Arabica, 30% Washed Robusta',
        tasteTags: 'würzig - nussig',
        taste: 'Mit einer würzigen Note und nussigem Aroma.',
    },
    image: Confianza,
    productCard: ConfianzaCard,
    isBioFairTrade: true,
};

export const productElBar: Product = {
    name: 'EL BAR',
    description: {
        ingredients: '90% Arabica, 10% Washed Robusta ',
        tasteTags: 'nussig - Karamellnote',
        taste: 'Malzig-würzig, mit Noten von Karamell und Beeren.',
    },
    image: ElBar,
    productCard: ElBarCard,
};

export const productElegante: Product = {
    name: 'ELEGANTE',
    description: {
        ingredients: '100% Arabica',
        tasteTags: 'nussig - schokoladig',
        taste: 'Nussig, mit leichter Süße und würziger Note.',
    },
    image: Elegante,
    productCard: EleganteCard,
};

export const productGrandioso: Product = {
    name: 'GRANDIOSO',
    description: {
        ingredients: '60% Arabica, 40% Washed Robusta',
        tasteTags: 'Bitterschokolade - nussig',
        taste: 'Leichte Süße, mit Noten von Kakao und Tabak.',
    },
    image: Grandioso,
    productCard: GrandiosoCard,
};

export const productMonteFino: Product = {
    name: 'MONTE FINO',
    description: {
        ingredients: '100% Arabica',
        tasteTags: 'nussig-lieblich',
        taste: 'Leichte Süße und milde Fruchtnoten.',
    },
    image: MonteFino,
    productCard: MonteFinoCard,
};

export const productOrangUtan: Product = {
    name: 'ORANG UTAN',
    description: {
        ingredients: '100% Arabica',
        tasteTags: 'süßlich-fruchtig, nussig',
        taste: 'Mit Noten von Nektarine und Pekannuss.',
        singleOriginText: 'Sortenreiner Kaffee aus Indonesien.',
    },
    image: OrangUtan,
    productCard: OrangUtanCard,
    subtitle: 'KAFFEE',
};

export const productPeru: Product = {
    name: 'PERU',
    description: {
        ingredients: '100% Arabica',
        tasteTags: 'nussig - beerig',
        taste: 'Süßlich, mit Noten von Karamell und Brombeere.',
        singleOriginText: 'Sortenreiner Kaffee aus Peru.',
    },
    image: Peru,
    productCard: PeruCard,
    subtitle: 'SINGLE ORIGIN',
};

export const productScala: Product = {
    name: 'SCALA',
    description: {
        ingredients: '60% Arabica, 40% Washed Robusta',
        tasteTags: 'schokoladig',
        taste: 'Mit würzigen Noten.',
    },
    image: Scala,
    productCard: ScalaCard,
};

export const productValioso: Product = {
    name: 'VALIOSO',
    description: {
        ingredients: '100% Arabica ',
        tasteTags: 'schokoladig - leicht fruchtig',
        taste: 'Geschmackliche Noten von Schokolade und Zitrusfrüchten.',
    },
    image: Valioso,
    productCard: ValiosoCard,
    isBioFairTrade: true,
};

export const productViena: Product = {
    name: 'VIENA',
    description: {
        ingredients: '70% Arabica, 30% Washed Robusta',
        tasteTags: 'würzig - schokoladig',
        taste: 'Mit Noten von Nuss und Nougat.',
    },
    image: Viena,
    productCard: VienaCard,
};

export const productECaliente: Product = {
    name: 'ESPRESSO CALIENTE',
    description: {
        ingredients: '55% Arabica, 45% Washed Robusta',
        tasteTags: 'Zartbitterschokolade - geröstete Haselnuss',
        taste: 'Süßlich, mit Noten von dunkler Schokolade und Gewürzen.',
    },
    image: ECaliente,
    productCard: ECalienteCard,
};

export const productEConfianza: Product = {
    name: 'ESPRESSO CONFIANZA',
    description: {
        ingredients: '70% Arabica, 30% Washed Robusta',
        tasteTags: 'nussig - schokoladig',
        taste: 'Geschmackliche Noten von Zartbitterschokolade und Haselnuss.',
    },
    image: EConfianza,
    productCard: EConfianzaCard,
    isBioFairTrade: true,
};

export const productEExpert: Product = {
    name: 'ESPRESSO EXPERT',
    description: {
        ingredients: '60% Arabica, 40% Washed Robusta',
        tasteTags: 'würzig-rauchig, schokoladig',
        taste: 'Herb, mit rauchig-würziger Note.',
    },
    image: EExpert,
    productCard: EExpertCard,
};

export const productEFuego: Product = {
    name: 'ESPRESSO FUEGO',
    description: {
        ingredients: '60% Arabica, 40% Washed Robusta',
        tasteTags: 'würzig - nussig',
        taste: 'Würzig, leicht fruchtig, mit Noten von Nuss und Schokolade.',
    },
    image: EFuego,
    productCard: EFuegoCard,
};

export const productEGiulio: Product = {
    name: 'ESPRESSO GIULIO',
    description: {
        ingredients: '70% Arabica, 30% Washed Robusta',
        tasteTags: 'würzig - Bitterschokolade',
        taste: 'Würzig, mit Noten von Bitterschokolade und Nuss.',
    },
    image: EGuiulio,
    productCard: EGiulioCard,
};

export const productEGrandioso: Product = {
    name: 'ESPRESSO GRANDIOSO',
    description: {
        ingredients: '60% Arabica, 40% Washed Robusta',
        tasteTags: 'würzig - sehr ausgewogen',
        taste: 'Wüzig, mit Noten von Kakao und Nuss.',
    },
    image: EGrandioso,
    productCard: EGrandiosoCard,
};

export const productEJavaBlu: Product = {
    name: 'ESPRESSO JAVA BLU',
    description: {
        ingredients: '100% Arabica ',
        tasteTags: 'nussig - süßlich',
        taste: 'Leicht fruchtig, mit Noten von Honig und Marzipan.',
    },
    image: EJavaBlu,
    productCard: EJavaBluCard,
};

export const productEVero: Product = {
    name: 'ESPRESSO VERO',
    description: {
        ingredients: '60% Arabica, 40% Washed Robusta',
        tasteTags: 'nussig - Karamellnote',
        taste: 'Samtig, mit Noten von Karamell und Kakao.',
    },
    image: EVero,
    productCard: EVeroCard,
};

export const productEVulcano: Product = {
    name: 'ESPRESSO VULCANO',
    description: {
        ingredients: '100% Arabica',
        tasteTags: 'Zartbitterschokolade - würzig',
        taste: 'Nussig, mit Noten von Karamell und dunkler Schokolade.',
    },
    image: EVulcano,
    productCard: EVulcanoCard,
};

export const productMonteDecaff: Product = {
    name: 'MONTE DECAFF',
    description: {
        ingredients: '100% Arabica',
        tasteTags: 'Zitrusnote - nussig',
        taste: 'Mit Noten von Honig und Kakao.',
    },
    image: MonteDecaff,
    productCard: MonteDecaffCard,
};

export const productSBio: Product = {
    name: 'SCHOKO',
    description: {
        ingredients: '',
        tasteTags: 'runder, vollmundiger Geschmack ',
        taste: 'Für die Zubereitung mit heißem Wasser in Automaten und Dispensern.',
    },
    image: SBio,
    isBioFairTrade: true,
    isChoc: true,
};

export const productSClassic: Product = {
    name: 'SCHOKO TYP CLASSIC',
    description: {
        ingredients: '',
        tasteTags: 'rund, aromatisch, vollmundig',
        taste: 'Für die Zubereitung mit heißem Wasser. Automatenfähig.',
    },
    image: SClassic,
    isChoc: true,
};

export const productSDunkleTrink: Product = {
    name: 'SCHOKO TYP DUNKLE TRINKSCHOKOLADE',
    description: {
        ingredients: '',
        tasteTags: 'vollmundig, intensiv, edel',
        taste: 'Für die Zubereitung mit heißem Wasser oder heißer Milch. Automatenfähig.',
    },
    image: SDunkleTrink,
    isChoc: true,
};

export const productSKaofix: Product = {
    name: 'SCHOKO KAOFIX',
    description: {
        ingredients: '',
        tasteTags: 'rund, aromatisch, vollmundig',
        taste: 'Für die Zubereitung mit heißem Wasser. Automatenfähig.',
    },
    image: SKaofix,
    isChoc: true,
};

export const productSMikao: Product = {
    name: 'SCHOKO MIKAO',
    description: {
        ingredients: '',
        tasteTags: 'fein, cremig, mild',
        taste: 'Für die Zubereitung mit heißer Milch. Automatenfähig.',
    },
    image: SMikao,
    isChoc: true,
};

export const productSTrink: Product = {
    name: 'SCHOKO TYP TRINKSCHOKOLADE',
    description: {
        ingredients: '',
        tasteTags: 'zartschmelzend und elegant',
        taste: 'Für die Zubereitung mit heißem Wasser. Automatenfähig.',
    },
    image: STrink,
    isChoc: true,
};

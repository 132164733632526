import styled from 'styled-components';
import backgroundImage from '../media/backgrounds/landing-page-image.jpg';
import handIcon from '../media/icons/hand.svg';
import { FontNexa, PageContainer, BackgroundImage } from './customStyledComponents';

const StartButton = styled.button`
    border: none;
    border-radius: 2.4px;
    background-color: #bbc791;
    color: #ffffff;
    font-family: inherit;
    font-size: 1.6rem;
    position: absolute;
    left: 40%;
    top: 60%;
    width: 20.6%;
    height: 6.3%;
    font-weight: 600;
    cursor: pointer;
    &:hover {
        background-color: #8bb443;
    }
`;
const Title = styled.h1`
    position: absolute;
    left: 25%;
    top: 40%;
    width: 50%;
    height: 20rem;
    text-align: center;
    color: #ffffff;
    font-size: 3.7rem;
    font-weight: normal;
    font-family: ${FontNexa};
`;
const Hand = styled.img`
    position: absolute;
    left: 50%;
    top: 64%;
    width: 8%;
    cursor: pointer;
`;

export function LandingPage({
    onStart,
}: {
    onStart: () => void;
}) {
    return (
        <PageContainer>
            <Title>WELCHE KAFFEESORTE PASST ZU MIR?</Title>
            <StartButton onClick={() => onStart()}>JETZT HERAUSFINDEN</StartButton>
            <Hand onClick={() => onStart()} src={handIcon}></Hand>
            <BackgroundImage src={backgroundImage} />
        </PageContainer>
    );
}

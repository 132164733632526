import styled from 'styled-components';
import { Choice } from './CustomTypes';
import { DecisionSlug } from './Enums';

interface StyleProps {
    isZubereitungsart?: boolean;
    isKaffeeEspressoWahl?: boolean;
    isPNG?: boolean;
 }
const ButtonWrapper = styled.div<StyleProps>`
    height: 80%;
    width: ${styleProps => styleProps.isZubereitungsart ? '11rem' : '18rem'};
    display: flex;
    
    align-items: center;
    flex-direction: column;
    padding: 1rem 1.2rem;
    padding-top: 2.7rem;
    padding-bottom: 0.35rem;
    background: rgba(255, 255, 255, 0.1);
`;
const ChoiceName = styled.h4`
    margin-top: 1rem;
    font-size: 1.25rem;
    font-weight: 900;
    text-align: center;
    letter-spacing: 0.1px;
`;
const ChoiceImg = styled.img<StyleProps>`
    height: 7rem;
    width: ${(styleProps) =>
    (styleProps.isKaffeeEspressoWahl && "13rem") ||
    (styleProps.isPNG && "auto") ||
    "9.5rem"};
`;

export function CustomButton(choice: Choice, isZubereitungsart: boolean, pathSlug: string) {
    return (
        <ButtonWrapper isZubereitungsart={isZubereitungsart}>
            <ChoiceImg
                isZubereitungsart={isZubereitungsart}
                isKaffeeEspressoWahl={
                    pathSlug === DecisionSlug.WAHLKAFFEESPRESSO
                }
                isPNG={
                    (pathSlug === DecisionSlug.WAHLBEHAELTER || pathSlug === DecisionSlug.WAHLMUEHLEN)
                }
                src={choice.image}
                alt=""
            />
            <ChoiceName>{choice.name}</ChoiceName>
        </ButtonWrapper>
    );
}

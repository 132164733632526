export enum DecisionSlug {
    ZUBEREITUNGSART = "ZUBEREITUNGSART",
    WAHLKAFFEESPRESSO = "WAHL KAFFEE ODER ESPRESSO",
    WAHLCREMAMILCHKAFFEE = "WAHL CAFFÈ CREMA ODER MILCHKAFFEE",
    GESCHMACKSRICHTUNG = "GESCHMACKSRICHTUNG",
    WAHLBEHAELTER = "ANZAHL BOHNENBEHÄLTER",
    WAHLMUEHLEN = "ANZAHL MÜHLEN",
}

export enum ProductSlugPrepMethod {
    VOLLAUTOMAT = "VOLLAUTOMAT",
    FILTERKAFFEE = "FILTERKAFFEE",
    FRENCH_PRESS = "FRENCH PRESS",
    SIEBTRAEGER = "SIEBTRÄGER",
    MOKKA_KANNE = "MOKKA KANNE",
    ARCA_KANNE = "ARCA KANNE",
    MONTE_DECAFF = "MONTE DECAFF",
    VENDINGAUTOMAT = "VENDINGAUTOMAT"
}

export enum AmountContainer {
    EIN_HALTER = 'EIN BOHNENBEHÄLTER',
    ZWEI_HALTER = 'ZWEI BOHNENBEHÄLTER',
    DREI_HALTER = 'DREI BOHNENBEHÄLTER',
}

export enum AmountMills {
    EINE_MUHLE = "EINE MÜHLE",
    ZWEI_MUHLE = "ZWEI MÜHLEN"
}

export enum BeanType {
    ESPRESSO = 'Espresso Bohnen-',
    CREMA = 'Crema Bohnen-',
    CHOCH = 'Schoko-Drink-',
    COFFEE = 'Kaffee-',
    NONE = ''
}
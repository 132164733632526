import styled from 'styled-components';
import { Choice, Decision } from './CustomTypes';
import backgroundImage from '../media/backgrounds/decision-background-image.jpg'
import { CustomButton } from './CustomButton';
import backIcon from '../media/icons/back-arrow.svg';
import refreshIcon from '../media/icons/refresh.svg';
import addUpArrow from '../media/icons/add-arrow.svg';
import addDownArrow from '../media/icons/arrow-down.svg';
import { decisionTree } from './decisionTree';
import { FontBroadcast, FontNexa, PageContainer, ButtonContainer, BackgroundImage, CDButton, ButtonImage } from './customStyledComponents';
import { ProductSlugPrepMethod } from './Enums';

interface styleProps {
    isDecaff?: boolean;
    isZubereitungsart?: boolean;
    amountElements?: number;
}
const DecisionTitle = styled.h1<styleProps>`
    position: absolute;
    left: 10%;
    top: 22%;
    width: 80%;
    height: 20%;
    text-align: center;
    color: #ffffff;
    font-size: 2.8rem;
    font-weight: normal;
    line-height: 4rem;
    font-family: ${FontNexa};
`;
const DecisionSubTitle = styled(DecisionTitle)`
    font-size: 1.35rem;
    width: 45%;
    left: 37%;
    top: 30%;
    line-height: 1.4;
    text-align: end;
    font-family: unset;
`;
const ChoiceList = styled.ul<styleProps>`
    padding: 0;
    display: flex;
    justify-content: space-around;
    position: absolute;
    list-style-type: none;
    top: 40%;
    left: ${(styleProps) =>
        (styleProps.amountElements === 2 && '29%') ||
        (styleProps.amountElements === 3 && '17.5%') ||
        (styleProps.amountElements !== 3 && styleProps.amountElements !== 2 && '10%')};
    width: ${(styleProps) =>
        (styleProps.amountElements === 2 && '42%') ||
        (styleProps.amountElements === 3 && '65%') ||
        (styleProps.amountElements !== 3 && styleProps.amountElements !== 2 && '80%')};
    color: #ffffff;
`;
const ChoiceListElement = styled.li`
    float: left;
    display: block;
    border: 1px #ffffff solid;
    height: 15rem;
    &:hover {
        background-color: #2F6881;
        cursor: pointer;
    }
`;
const NavButton = styled(CDButton)`
    border: 1px #ffffff solid;
    color: #ffffff;
`;
const DecaffButton = styled(NavButton)`
    margin-right: 0;
    margin-top: 2.5rem;
`;
const AdditionalHeader = styled.div`
        font-family: ${FontBroadcast};
        font-size: 5rem;
        color: #c48d6e;
        line-height: 6rem;
        margin: 0rem 0rem;
`;
const AdditionalContent = styled.div`
        width: 70%;
        font-size: 1.3rem;
        margin: 0rem 0rem;
`;
const AdditionalContentWrapper = styled.div<styleProps>`
    position: absolute;
    top: 66%;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: #ffffff;
    margin: 2rem 0rem;
    right: ${(styleProps) => (styleProps.isDecaff ? '10%' : '22%')};
    text-align: ${(styleProps) => (styleProps.isDecaff ? 'right' : 'left')};
`;
const AdditionalArrow = styled.img`
    position: absolute;
    width: 4.2rem;
`;
const UpwardArrow = styled(AdditionalArrow)`
    top: -13vh;
    right: 3.5vw;
`;
const DownwardArrow = styled(AdditionalArrow)`
    top: 9vh;
    right: 18vw;
    width: 6rem;
`;

export const DecisionPage = ({
    path,
    restart,
    determineNextPage,
    goToPreviousPage,
    previousPageName,
}: {
    path: Decision;
    restart: () => void;
    determineNextPage: (choice: Choice) => void;
    goToPreviousPage: () => void;
    previousPageName: string;
}) => {
    const isZubereitungsart = path.slug === 'ZUBEREITUNGSART';
    return (
        <PageContainer>
            <DecisionTitle>{path.title}</DecisionTitle>
            {path.subtitle && <DecisionSubTitle>{path.subtitle}</DecisionSubTitle>}
            <ChoiceList amountElements={path.choices.length}>
                {path.choices.map((choice) => {
                    if (choice.name !== ProductSlugPrepMethod.MONTE_DECAFF) {
                        return (
                            <ChoiceListElement key={choice.name} onClick={() => determineNextPage(choice)}>
                                {CustomButton(choice, isZubereitungsart, path.slug)}
                            </ChoiceListElement>
                        );
                    }

                    return null;
                })}
            </ChoiceList>
            <ButtonContainer>
                {!isZubereitungsart && (
                    <NavButton onClick={() => goToPreviousPage()}>
                        <ButtonImage src={backIcon} alt='' />
                        ZURÜCK ZUR {previousPageName}
                    </NavButton>
                )}
                <NavButton onClick={() => restart()}>
                    <ButtonImage src={refreshIcon} alt='' />
                    NEUSTART
                </NavButton>
            </ButtonContainer>
            {path.additionalContent !== undefined && (
                <AdditionalContentWrapper isDecaff={path.additionalContent.isDecaff}>
                    <AdditionalHeader>{path.additionalContent.header}</AdditionalHeader>
                    <AdditionalContent>{path.additionalContent.content}</AdditionalContent>
                    {path.additionalContent.isDecaff ? (
                        <>
                            <DecaffButton
                                onClick={() => determineNextPage(decisionTree.choices[decisionTree.choices.length - 1])}
                            >
                                MEHR ERFAHREN
                            </DecaffButton>
                            <DownwardArrow src={addDownArrow} />
                        </>
                    ) : (
                        <UpwardArrow src={addUpArrow /* REVIEW: Verrutscht beim Verkleinern des Screens */} />
                    )}
                </AdditionalContentWrapper>
            )}
            <BackgroundImage src={backgroundImage} alt='' />
        </PageContainer>
    );
};

import { decisionTree } from './lib/decisionTree';
import { useEffect, useState } from 'react';
import { LandingPage } from './lib/LandingPage';
import { Choice, DecisionTypePage, ProductSlug, ProductTypePage } from './lib/CustomTypes';
import { DecisionPage } from './lib/DecisionPage';
import { ProductPage } from './lib/ProductPage';
import { DecisionSlug } from './lib/Enums';

export function App() {
    const [currentPage, setCurrentPage] = useState<DecisionTypePage | ProductTypePage | undefined>();
    const [pages, setPages] = useState<(DecisionTypePage | ProductTypePage | undefined)[]>([]);
    const [secondsLeftToReset, setSecondsLeftToReset] = useState(150);
    const [productSlug, setProductSlug] = useState<ProductSlug>();

    useEffect(() => {
        let resetInterval = setInterval(() => {
            if (secondsLeftToReset > 0) {
                setSecondsLeftToReset(secondsLeftToReset - 1);
            } else {
                restart();
            }
        }, 1000);
        return () => {
            clearInterval(resetInterval);
        };
    }, [secondsLeftToReset]);

    useEffect(() => {
        setSecondsLeftToReset(150);
    }, [currentPage]);

    function navigationToInitialDecision() {
        setPages([...pages, currentPage]);
        setCurrentPage({ type: 'decision', decision: decisionTree });
    }

    function determineNextPageSetPagesAndProductSlug(choice: Choice) {
        setPages([...pages, currentPage]);
        changeProductSlug(choice);
        if (choice.decision) {
            setCurrentPage({ type: 'decision', decision: choice.decision });
        } else if (choice.products) {
            setCurrentPage({ type: 'products', products: choice.products });
        } else {
            throw new Error();
        }
    }

    function changeProductSlug(choice: Choice) {
        if (productSlug?.prepMethod === undefined || productSlug?.prepMethod === '') {
            setProductSlug({ prepMethod: choice.name, flavour: choice.name });
        } else if (choice.products) {
            setProductSlug({ prepMethod: productSlug?.prepMethod, flavour: choice.name, image: productSlug?.image });
        }
    }

    function goToPreviousPageAndSetPreviousPageName() {
        const newPages = [...pages];
        const previousPage = newPages.pop();
        if (!previousPage) {
            throw new Error('There is no previous Page');
        } else if (previousPage.type === 'decision' && previousPage.decision.slug === DecisionSlug.ZUBEREITUNGSART) {
            setProductSlug({ prepMethod: '', flavour: '' });
        }
        setPages(newPages);
        setCurrentPage(previousPage);
    }

    function determinePreviousPageName(): string {
        const prevPage = pages[pages.length - 1];
        if (prevPage !== undefined && prevPage.type === 'decision') {
            return prevPage.decision.slug;
        } else {
            return '';
        }
    }

    function restart() {
        setPages([]);
        setCurrentPage(undefined);
        setProductSlug({ prepMethod: '', flavour: '' });
    }

    if (currentPage === undefined) {
        return (
            <>
                <LandingPage onStart={navigationToInitialDecision} />
            </>
        );
    }
    switch (currentPage.type) {
        case 'decision':
            return (
                <DecisionPage
                    path={currentPage.decision}
                    restart={restart}
                    determineNextPage={determineNextPageSetPagesAndProductSlug}
                    goToPreviousPage={goToPreviousPageAndSetPreviousPageName}
                    previousPageName={determinePreviousPageName()}
                />
            );
        case 'products':
            if (currentPage.products !== undefined) {
                return (
                    <ProductPage
                        products={currentPage.products}
                        restart={restart}
                        goToPreviousPage={goToPreviousPageAndSetPreviousPageName}
                        previousPageName={determinePreviousPageName()}
                        productSlug={productSlug !== undefined ? productSlug : { prepMethod: '', flavour: '' }}
                    />
                );
            } else {
                return <p>Ein Fehler ist aufgetreten (Products not defined).</p>
            }
    }
}

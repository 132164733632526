import styled from 'styled-components';
import { Product, ProductSlug } from './CustomTypes';
import backgroundImage from '../media/backgrounds/result-background-image.jpg';
import fairtradeIcon from '../media/icons/fairtrade-icon.png';
import fill from '../media/fill-1.png';
import backIcon from '../media/icons/back-arrow_brown.svg';
import refreshIcon from '../media/icons/refresh_brown.svg';
import {
    PageContainer,
    ButtonContainer,
    BackgroundImage,
    ButtonImage,
    ProductsContainer,
    FairtradeIcon,
    NavButton,
    ProductDescriptionWrapper,
    ProductImage,
    ProductListElement,
    ProductName,
    ProductP,
    ProductTasteTags,
    ResultHeader,
    ResultSlug,
} from './customStyledComponents';
import { ProductSlugPrepMethod } from './Enums';
import { productMonteFino } from './products';

interface StyleProps {
    amountProducts: number;
}

const ResSlug = styled(ResultSlug)`
    width: 21%;
    grid-template:
        'image title title' 3fr
        'image fill fill' 1fr
        'image tag tag' 2fr /2fr 4fr 4fr;
`;

const Container = styled(ProductsContainer)<StyleProps>`
    display: flex;
    justify-content: space-around;
    left: ${(styleProps) =>
        (styleProps.amountProducts === 1 && '35%') ||
        (styleProps.amountProducts === 2 && '30%') ||
        (styleProps.amountProducts === 3 && '22.5%') ||
        (styleProps.amountProducts === 4 && '17.5%') ||
        (styleProps.amountProducts === 5 && '12.5%') ||
        (styleProps.amountProducts === 6 && '10%')};
    width: ${(styleProps) =>
        (styleProps.amountProducts === 1 && '30%') ||
        (styleProps.amountProducts === 2 && '40%') ||
        (styleProps.amountProducts === 3 && '55%') ||
        (styleProps.amountProducts === 4 && '65%') ||
        (styleProps.amountProducts === 5 && '75%') ||
        (styleProps.amountProducts === 6 && '80%')};
`;
const ListElement = styled(ProductListElement)<StyleProps>`
    width: ${(styleProps) =>
        (styleProps.amountProducts === 1 && '100%') ||
        (styleProps.amountProducts === 2 && '30%') ||
        (styleProps.amountProducts === 3 && '25%') ||
        (styleProps.amountProducts === 4 && '20%') ||
        (styleProps.amountProducts === 5 && '15%') ||
        (styleProps.amountProducts === 6 && '10%')};
`;

export function ProductPage({
    products,
    restart,
    goToPreviousPage,
    previousPageName,
    productSlug,
}: {
    products: Product[];
    restart: () => void;
    goToPreviousPage: () => void;
    previousPageName: string;
    productSlug: ProductSlug;
    }) {
    return (
        <PageContainer>
            <ResultHeader>
                IHRE PERSÖNLICHEN <h1>Kaffee-Empfehlungen</h1>
            </ResultHeader>
            <ResSlug>
                <h4>
                    {productSlug.prepMethod === ProductSlugPrepMethod.MONTE_DECAFF
                        ? 'entkoffeiniert'
                        : productSlug.prepMethod}
                </h4>
                <img className='fill' src={fill} alt='' />
                {productSlug.prepMethod !== ProductSlugPrepMethod.MOKKA_KANNE &&
                    productSlug.prepMethod !== ProductSlugPrepMethod.MONTE_DECAFF && (
                        <p>{productSlug.flavour.replace('ER KAFFEE', '')}</p>
                    )}
            </ResSlug>
            <Container amountProducts={products.length}>
                {products.map(function (product) {
                    return (
                        <ListElement amountProducts={products.length}>
                            <ProductImage src={product.image} alt={`Produktverpackung von ${product.name}`} />
                            <ProductName>
                                {product.name}
                                <br></br>
                                {getProductSubtitle(product)}
                            </ProductName>
                            <ProductDescriptionWrapper>
                                <ProductP>
                                    {product.subtitle ? (
                                        <>
                                            {product.description.singleOriginText} <br />
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    {product.description.ingredients}
                                </ProductP>
                                <ProductTasteTags>{product.description.tasteTags}</ProductTasteTags>
                                <ProductP>{product.description.taste}</ProductP>
                            </ProductDescriptionWrapper>
                            {product.isBioFairTrade && <FairtradeIcon src={fairtradeIcon} />}
                        </ListElement>
                    );
                })}
            </Container>
            <ButtonContainer>
                <NavButton onClick={() => goToPreviousPage()}>
                    <ButtonImage src={backIcon} alt='' />
                    ZURÜCK ZUR {previousPageName}
                </NavButton>
                <NavButton onClick={() => restart()}>
                    <ButtonImage src={refreshIcon} alt='' />
                    NEUSTART
                </NavButton>
            </ButtonContainer>
            <BackgroundImage src={backgroundImage} alt='' />
        </PageContainer>
    );
}

function getProductSubtitle(product: Product) {
    if (product.isBioFairTrade) {
        return 'BIO FAIRTRADE';
    } else if (product.subtitle) {
        return product.subtitle;
    } else if (product === productMonteFino) {
        return '(EXTRA MILD)';
    } else {
        return '';
    }
}

import styled from "styled-components";

export const FontNexa = 'Nexa Rust Sans Black1';
export const FontBroadcast = "Broadcast Matter";

export const PageContainer = styled.div`
    position: relative;
    max-height: 1080px;
    max-width: 1920px;
`;
export const BackgroundImage = styled.img`
    width:100%;
`;
export const ButtonImage = styled.img`
    margin-right: 0.8rem;
    height: 1.3rem;
`;
export const ButtonContainer = styled.div`
    position: absolute;
    top: 85%;
    left: 5%;
`;
export const CDButton = styled.button`
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.1);
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 1.6rem;
    padding: 0.75rem 1.2rem;
    &:hover {
        background-color: #bbc791;
        cursor: pointer;
    }
`;
export const ProductCardBackDrop = styled.div`
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
`;
export const ProductCard = styled.img`
    cursor: pointer;
    position: absolute;
    width: 35%;
    left: 32.5%;
    top: 5%;
    z-index: 5;
`;
export const ResultHeader = styled.div`
    position: absolute;
    color: #49190b;
    top: 20%;
    left: 28%;
    width: 44%;
    text-align: center;
    font-family: ${FontNexa};
    font-size: 1.1rem;
    line-height: 3.3rem;
    margin: 0;
    margin-top: 1rem;
    h1 {
        font-family: ${FontBroadcast};
        font-size: 6rem;
        font-weight: normal;
        letter-spacing: 1.05px;
        color: #8f512a;
        margin: 0;
        margin-top: 0.8rem;
    }
`;
export const ResultSlug = styled.div`
    position: absolute;
    top: 8%;
    right: 0%;
    width: 25%;
    height: 12%;
    display: grid;
    grid-template:
        'image title title' 3fr
        'image fill fill' 1fr
        'image tag tag' 2fr /30% 38% 42%;
    background-color: #bbc791;
    font-family: ${FontNexa};
    color: #ffffff;
    & h4 {
        font-size: 1.85rem;
        grid-area: title;
        margin: auto auto;
        margin-left: 0;
    }
    & .fill {
        max-width: 100%;
        grid-area: fill;
    }
    & p {
        grid-area: tag;
        line-height: 2rem;
        font-size: 1.3rem;
        margin: 0.25rem 0;
    }
    & .navImg {
        max-height: 55%;
        max-width: 90%;
        margin: auto;        
        grid-area: image;
    }
`;
export const NavButton = styled(CDButton)`
    border: 1px #49190b solid;
    color: #49190b;
`;
export const ProductsContainer = styled.div`
    padding: 0;
    position: absolute;
    list-style-type: none;
    top: 40%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    left: 17.5%;
    width: 65%;
    color: #49190b;
    letter-spacing: 0.09px;
    line-height: 1.8rem;
    & {
        margin: 0;
        padding: 0;
    }
    &::-webkit-scrollbar {
        display: none;
    }
`;
export const ProductListElement = styled.li`
    position: relative;
    text-align: center;
    white-space: normal;
    display: inline-block;
    vertical-align: top;
    width: 20%;
    margin: 0rem 1rem;
`;
export const ProductName = styled.h3`
    font-weight: 900;
    line-height: 1.6rem;
    letter-spacing: 0.1px;
    margin: 0.5rem 0rem;
`;
export const ProductDescriptionWrapper = styled.div`
    margin: 0rem 0rem;
`;
export const ProductImage = styled.img`
    width: auto;
    height: 9rem;
`;
export const ProductTasteTags = styled.h4`
    font-weight: bold;
    line-height: 1.6rem;
    margin: 0.5rem 0rem;
`;
export const FairtradeIcon = styled.img`
    width: 50%;
`;
export const ProductP = styled.p`
    margin: 0.5rem 0rem;
`;